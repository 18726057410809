.home
    padding: 5px 10px
    h1
        text-transform: uppercase
        font-weight: 400
        font-size: 1.5rem
        margin-top: 30px
    h2
        font-size: 3.2rem
        font-weight: 600
        line-height: 52px
        margin-top: 20px
        max-width: 600px
    p
        font-weight: 400
        font-size: 1.1rem
        line-height: 25px
        margin-top: 20px
        max-width: 600px
    .homeText1Btn
        background-color: #50a4f9
        border: none
        color: white
        padding: 5px 10px
        text-align: center
        text-decoration: none
        display: inline-block
        font-size: 1rem
        margin: 4px 2px
        cursor: pointer
        transition-duration: 0.4s
        border-radius: 12px
        font-weight: 400
        text-transform: uppercase

    @keyframes pulse
        0%
            transform: scale(1)

        50%
            transform: scale(1.1)

        100%
            transform: scale(1)
    .homeText1Btn
        animation: pulse 1.5s infinite
    .homeText2, .homeText3
        width: 0
        height: 0
        font-size: 0
    .homeText2Active, .homeText3Active
        font-weight: 400
        font-size: 1.1rem
        line-height: 25px
        margin-top: 20px
        max-width: 600px
        transition: all 0.5s ease
        margin-bottom: 20px
        margin-top: 5px
    .homeCheckBtnTitle1, .homeCheckBtnTitle2
        font-weight: 400
    .homeCheckBtnTitle2
        margin-top: 5px
    .homeCheckBtnTitle
        margin-top: 20px
        margin-bottom: 10px
        text-align: center
    .homeCheckBtn
        display: block
        margin: 0 auto
        text-align: center
    .homeImgWrapp
        margin-top: 40px
        margin-bottom: 50px
        display: grid
        grid-template-columns: repeat(2, 1fr)
        grid-gap: 20px

        div
            position: relative

        img
            width: 100%
            height: auto
            object-fit: cover

        h2
            position: absolute
            bottom: 0
            left: 0
            padding: 20px
            margin: 0
            background-color: rgba(0, 0, 0, 0.6)
            color: #fff
            font-size: 1.1rem
            font-weight: bold
            text-transform: uppercase
            width: 100%
            box-sizing: border-box

        p
            position: absolute
            top: 0
            left: 0
            padding: 1rem
            margin: 0
            background-color: rgba(255, 255, 255, 0.8)
            color: #000
            font-size: 16px
            line-height: 1.5
            width: 100%
            box-sizing: border-box
    .homeBeginBtn
        display: block
        margin: 0 auto
        text-transform: uppercase
        width: auto
        height: auto
        margin-bottom: 40px
    .homeBeginBtnUp
        margin-top: 30px
@media (max-width: 821px)
    .home

        .homeImgWrapp
            margin: 40px auto
            grid-template-columns: repeat(1, 1fr)
            max-width: 600px
@media (max-width: 500px)
    .homeCheckBtn
        font-size: 1.5rem

@media (max-width: 400px)
    .home
        h2
            font-size: 2.8rem
            line-height: 2.7rem
        .homeCheckBtn
            font-size: 1.2rem
            line-height: 2.2rem
            height: auto
