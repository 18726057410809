.popup
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    justify-content: center
    align-items: center
    padding: 20px
    z-index: 100

.popupContent
    position: relative
    display: inline-flex
    flex-direction: column
    gap: 10px
    background-color: #fff
    padding: 2px
    padding-top: 10px
    max-width: 500px
    width: 90vw
    height: 100vh
    text-align: center
    text-transform: uppercase
    overflow: auto

    h2
        margin-bottom: 15px
    p
        font-weight: 400
    h4
        color: green
.closeBtn
    position: absolute
    top: 5px
    right: 10px
    width: 70px !important
    height: 40px
.img
    display: flex
    flex-wrap: wrap
    align-items: center
    gap: 3px
    img
        width: 50px
.imgBtnWrap
    position: relative
.cardEditImgBtn
    padding: 2px !important
    border-radius: 0 !important
    position: absolute
    left: 0
    top: 0
    line-height: 10px
    width: 100%
    height: 100%
    background-color: #007bff21 !important
    font-size: 25px !important
    color: red !important
.saveBtn
    margin-top: 10px
@media (max-width: 821px)
    .popup
        padding: 0
    .popupContent
        padding: 0
        width: 100vw
        height: 100vh

        p
            font-size: 1.3rem
