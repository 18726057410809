.popup
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0, 0, 0, 0.9)
    display: flex
    justify-content: space-between
    align-items: center
    z-index: 100

.closeBtn
    position: absolute
    top: 5px
    right: 10px
    width: 70px !important
    height: 40px
