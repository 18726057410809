#nav-icon2 {
    display: none;
}



@media (max-width: 1081px){
    #nav-icon2 {        
        display: block;
        width: 60px;
        height: 45px;
        position: relative;
        margin-left: auto;
        transform: rotate(0deg);
        transition: .5s ease-in-out;
        cursor: pointer;
        z-index: 30;
    }
    #nav-icon2 span {
        display: block;
        position: absolute;
        height: 9px;
        width: 50%;
        background: #337AB7;
        opacity: 1;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }
    #nav-icon2 span:nth-child(even) {
        left: 50%;
        border-radius: 0 9px 9px 0;
    }
    #nav-icon2 span:nth-child(odd) {
        left:0px;
        border-radius: 9px 0 0 9px;
    }
    #nav-icon2 span:nth-child(1),
    #nav-icon2 span:nth-child(2) {
        top: 0px;
    }
    #nav-icon2 span:nth-child(3),
    #nav-icon2 span:nth-child(4) {
        top: 18px;
    }
    #nav-icon2 span:nth-child(5),
    #nav-icon2 span:nth-child(6) {
        top: 36px;
    }
    #nav-icon2.open span:nth-child(1),
    #nav-icon2.open span:nth-child(6) {
        transform: rotate(45deg);
    }
    #nav-icon2.open span:nth-child(2),
    #nav-icon2.open span:nth-child(5) {
        transform: rotate(-45deg);
    }
    #nav-icon2.open span:nth-child(1) {
        left: 5px;
        top: 7px;
    }
    #nav-icon2.open span:nth-child(2) {
        left: calc(50% - 5px);
        top: 7px;
    }
    #nav-icon2.open span:nth-child(3) {
        left: -50%;
        opacity: 0;
    }
    #nav-icon2.open span:nth-child(4) {
        left: 100%;
        opacity: 0;
    }
    #nav-icon2.open span:nth-child(5) {
        left: 5px;
        top: 29px;
    }
    #nav-icon2.open span:nth-child(6) {
        left: calc(50% - 5px);
        top: 29px;
    }
}
