.Header
    display: grid
    grid-template-columns: auto 60px 2fr 1fr
    align-items: center
    padding: 5px 10px
    background-color: #fff
    ul
        grid-column-start: 3

    .logo
        width: 50px
        img
            width: 100%

    ul
        display: flex
        justify-content: space-between
        transition: all .3s ease-out
        li
            font-size: 1.2rem
            color: #385898
            position: relative
            cursor: pointer
            transition: all .3s ease-out

            &::after
                content: ''
                position: absolute
                bottom: -2px
                left: 0
                width: 0%
                height: 2px
                background-color: #385898
                transition: width 0.5s ease-in-out
        .adminMenu
            position: absolute
            left: 170px
            top: 55px
        li:hover::after
            width: 100%

    .headerBtnWrap
        display: flex
        justify-content: flex-end
        align-items: center
        grid-column-start: 4
        .login, .register, .logOut
            display: inline-block
            box-sizing: border-box
            margin-right: 10px
            padding: 0 20px
            outline: none
            border: none
            border-radius: 6px
            height: 40px
            line-height: 40px
            font-size: 17px
            font-weight: 600
            color: #385898
            background-color: #e7f3ff
            cursor: pointer
            user-select: none
            appearance: none
            touch-action: manipulation

            &:focus-visible
                box-shadow: 0 0 0 2px #666

            &:hover
                background-color: #DBE7F2

        .logoutWrap
            display: flex
            align-items: center
            gap: 10px
            .logOut
                display: block

            .userWrap
                font-size: 1.2rem
                color: #385898
                span
                    color: #21a101
.chatBotBtn
    max-width: 210px
    text-align: center
    background-color: #19c37d
    border-radius: 5px
    padding: 10px
    color: #fff
    margin: 0 auto
    cursor: pointer
    margin-bottom: 10px
// Burger menu
.Header.headerBurger
    grid-template-columns: 1fr 2fr 1fr
    ul
        position: fixed
        display: flex
        flex-direction: column
        justify-content: center
        text-align: center
        gap: 50px
        width: 100%
        height: 100%
        top: 10px
        left: 0
        z-index: 10
        background-color: #f2f2f2
        transition: all .3s ease-out
        div
            display: flex
            flex-direction: column
            gap: 50px

        li
            font-size: 1.5rem
            width: 100%

            &::after
                display: none

@media (max-width: 1081px)
    .Header
        grid-template-columns: auto 0 0 auto auto
        z-index: 1000
        .logo
            font-size: 3.5rem
        ul
            height: 100%
            position: absolute
            left: 0
            li
                width: 0
                height: 0
                font-size: 0

        .headerBtnWrap

            .login, .register, .logOut
                height: 60px
                line-height: 60px
                font-size: 28px

@media (max-width: 1001px)
    .Header
        .logo
            font-size: 2.5rem
@media (max-width: 750px)
    .Header
        .logo
            font-size: 2.0rem
        .headerBtnWrap
            .login, .register, .logOut
                height: 50px
                line-height: 50px
                font-size: 25px
@media (max-width: 685px)
    .Header, .Header.headerBurger
        .logo
            font-size: 2.0rem
        .headerBtnWrap
            .login, .register, .logOut
                height: 50px
                font-size: 23px
@media (max-width: 631px)
    .Header, .Header.headerBurger
        display: flex
        justify-content: space-between
        .logo
            display: none
        .headerBtnWrap
            margin-right: auto
            .login, .register, .logOut
                font-size: 30px
@media (max-width: 600px)
    .Header, .Header.headerBurger
        .logo
            display: none
@media (max-width: 530px)
    .Header, .Header.headerBurger

        .logo
            display: none
        .headerBtnWrap
            .login, .register, .logOut
                font-size: 17px
