.form-warning
    text-align: center
    margin-bottom: 20px
    font-size: 1rem
    color: red
.rentFormTitle
    text-align: center
    margin-bottom: 20px
.rentBtnLink
    text-align: center
    margin: 30px
    .btn
        margin: 0 auto
        .rentBtnLinkText
            font-size: 22px
