.about
    padding: 60px 15px
    h2
        text-align: center

        margin-bottom: 35px
        font-size: 1.5rem
    p
        margin-bottom: 15px
        font-size: 1.2rem
        font-weight: 400
