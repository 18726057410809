.request
    padding: 40px 15px
    text-align: center
    h2, h3, p
        text-transform: uppercase
    h2
        font-size: 1.5rem
        margin-bottom: 10px
    h3
        font-size: 1.2rem
        margin-bottom: 10px
    p
        font-size: 1.1rem
        margin-bottom: 30px
    .homeBeginBtn
        margin-bottom: 30px
        text-transform: uppercase
    .filterWrapp
        display: flex
        width: 100%
        justify-content: center
        gap: 10%
        margin: 0 auto

        label, select
            display: block
            margin-bottom: 8px

        select
            width: 100%
            height: 32px
            border: 1px solid #ccc
            border-radius: 4px
            background-color: #fff
            font-size: 16px
            padding: 4px

        option
            font-size: 16px

        table td:last-child, table th:last-child
            text-align: right

        tbody tr:hover
            background-color: #ddd

        .error
            color: red

.requestCarts
    padding: 0 20px
    padding-bottom: 40px
    .cartHeader
        display: grid
        grid-template-columns: 3fr repeat(5, 1fr)
        grid-template-rows: 1fr
        grid-column-gap: 0px
        grid-row-gap: 0px
        margin-bottom: 15px
        background: #e7f3ff

        div
            background: #e7f3ff
            font-size: 1.3rem
            color: #385898
            font-weight: 500
            padding: 10px
            display: flex
            align-items: center
            margin: 0 auto
        .cartHeaderDescr
            min-width: 255px
    .cartHeaderReviews
        grid-template-columns: 3fr repeat(3, 1fr)
    .cartWrapp
        margin-bottom: 5px
        .cartDescrMedia
            display: none
        .cart
            display: grid
            grid-template-columns: 3fr repeat(5, 1fr)
            grid-template-rows: 1fr
            grid-column-gap: 0px
            grid-row-gap: 0px
            background: #fff
            div
                background: #fff
                font-size: 1.3rem
                font-weight: 400
                padding: 10px
                display: flex
                align-items: center
                margin: 0 auto
            .cartDescr
                min-width: 255px
            .cartDescrAllReviews
                flex-direction: column
                div
                    display: block
                    span
                        color: #0732f5
                    .averageScore
                        text-transform: uppercase
                        font-size: 1rem
                        background-color: #e7f3ff
                .impression
                    font-weight: 500
                    font-size: 1.6rem
                    margin-top: 10px
                    margin-bottom: 10px
        .cartDescrMedia
            .cartDescrAllReviews
                flex-direction: column
                div
                    display: block
                    span
                        color: #0732f5
                    .averageScore
                        text-transform: uppercase
                        font-size: 1rem
                        background-color: #e7f3ff
                        font-weight: 400
                .impression
                    font-weight: 500
                    font-size: 1.4rem
                    margin-top: 10px
                    margin-bottom: 10px

        .reviewsCart
            grid-template-columns: 3fr repeat(3, 1fr)
            .veryBadScore
                color: #ff0000
            .BedScore
                color: #ff5100
            .mediumScore
                color: #ffeb00
            .goodScore
                color: #00c4ff
            .veryGoodScore
                color: #048308
            .articleTime
                opacity: 0.5
                margin-top: 5px
                font-size: 1.2rem

        .active
            color: red
        .BodyBtn
            div
                right: -37px
                height: 30px
                text-align: center
                background-color: #385898
                border-radius: 5px
                padding: 5px
                color: #fff
                cursor: pointer
                flex-direction: column
                p
                    margin: 0
                    font-size: 0.8rem
                    text-transform: uppercase
.homeBeginBtn
    margin: 0 auto
.cabinet
    .cartHeader
        grid-template-columns: 3fr repeat(4, 1fr)
    .cartWrapp
        .cart
            grid-template-columns: 3fr repeat(4, 1fr)

@media screen and ( max-width: 721px )
    .requestCarts
        .cartHeader
            grid-template-columns: repeat(4, 1fr)
            .cartHeaderDescr
                display: none
            .cartHeaderStar
                display: none
            .reviewsStar
                display: block
        .cartHeaderReviews
            grid-template-columns: repeat(3, 1fr)
            div
                font-size: 1rem
        .cartWrapp
            .cartDescrMedia
                display: flex
                background: #fff
                padding-top: 5px
                align-items: center
                .BodyBtn
                    margin-left: auto

            .cart
                grid-template-columns: repeat(4, 1fr)
                .cartDescr
                    display: none
                .BodyBtn
                    display: none
                .cartDescrAllReviews
                    display: none
            .reviewsCart
                grid-template-columns: repeat(3, 1fr)
                .star, .cartCity, .cartIsPawned
                    font-size: 1rem

@media screen and ( max-width: 551px )

@media screen and ( max-width: 550px )
