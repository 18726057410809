.vnjchatbot-link
  text-align: center
  color: #222

  div
    border: solid 1px #337AB7

    p
      padding: 5px
      font-size: 20px
      animation: pulse 2s infinite

@keyframes pulse
  0%
    transform: scale(1)

  50%
    transform: scale(1.1)

  100%
    transform: scale(1)
