.not-found {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #F0F2F5;
    h1 {
        font-size: 120px;
        font-weight: bold;
        color: #1877F2;
        margin: 0;
      }
      
      h2 {
        font-size: 40px;
        font-weight: bold;
        color: #1C1E21;
        margin: 20px 0;
      }
      
      p {
        font-size: 24px;
        color: #1C1E21;
        margin: 0;
        text-align: center;
      }
      
      a {
        font-size: 24px;
        color: #1877F2;
        text-decoration: none;
        margin-top: 20px;
        transition: color 0.2s ease-in-out;
      }
      
      a:hover {
        color: #1C1E21;
      }
  }
  
  
  