.SellApartment
    .sell-apartment-title
        font-size: 1.4rem
        margin-bottom: 10px
    li
        margin-bottom: 5px
        color: #000
        font-weight: 400
    .SellApartment-acc
        margin-bottom: 30px
    .SellApartment-title-h1
        margin-bottom: 20px
        text-align: center
        font-weight: 500
    .SellApartment-form
        div
            display: flex
            flex-direction: column
            max-width: 400px
            margin: 0 auto
            margin-bottom: 15px
            label
                font-size: 1.2rem
                color: #333
                font-weight: 400
            input
                padding: 10px
                border-radius: 5px
                border: solid 1px #e7f3ff
                font-size: 1.1rem
    .SellApartment-btn
        display: block
        margin: 0 auto
        margin-top: 10px
