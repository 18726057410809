.buyItem
    padding: 40px 15px
    text-align: center
    h2, h3, p
        text-transform: uppercase
    h2
        font-size: 1.5rem
        margin-bottom: 10px
    h3
        font-size: 1.2rem
        margin-bottom: 10px
    p
        font-size: 1.1rem
        margin-bottom: 30px

    thead
        top: 0
        tr

            th
                color: #385898
                background-color: #e7f3ff
                font-weight: 500
    tbody

        tr
            background-color: #fff
            border-bottom: 15px solid #f2f2f2
            font-size: 1rem

            td
                max-width: 65vw

            td:first-child
                min-width: 30vw

    table
        border-collapse: collapse
        width: 100%

        th, td
            padding: 8px
            text-align: left
            bottom: none
        td
            font-weight: 400

        th
            background-color: #f2f2f2

    table td:last-child, table th:last-child
        text-align: right

    tbody tr:hover
        background-color: #ddd

.apartmentForm
    margin: 0 auto
    margin-bottom: 40px

@media screen and ( max-width: 551px )
    .buyItem
        position: relative
        table
            width: 91.5vw
            thead
                tr
                    .th
                        position: absolute
                        top: 110px
                        display: block
                        width: 87.5vw

            tbody
                tr
                    .td
                        position: absolute
                        top: 147px
                        display: block
                        max-width: 87.5vw
                        background-color: #fff
                        border-bottom: 1px solid #e7f3ff
    .apartmentForm
        margin-top: 85px
@media screen and ( max-width: 422px )
    .apartmentForm
        form
            label
                input
                    width: 90vw
                textarea
                    width: 90vw

@media screen and ( max-width: 376px )
    .buyItem
        table
            tbody
                tr
                    border-bottom: 40px solid #f2f2f2
                    position: relative
                    .td
                        top: 70px
                        max-width: 90vw
