.buttonWrap
  text-align: center

.button   
  background-color: #25D366
  color: white
  font-size: 1.3em
  margin: 1em
  padding: 10px 20px
  border: 2px solid #25D366
  border-radius: 3px
  cursor: pointer
  transition: all 0.3s ease-out


.button:hover 
  background-color: #1E0A52
  border-color: #1E0A52

