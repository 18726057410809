.CheckHouse
    padding: 20px
    .CheckHouse-title
        font-size: 2rem
        font-weight: 500

    .CheckHouse-descr-answer
        font-size: 0
        width: 100%
        max-height: 0
        background-color: #fff
        box-sizing: border-box
        padding: 0
        font-size: 0
        font-weight: 0
        opacity: 0.9
        transition: all 0.5s ease-in-out, all 0.5s ease-in-out
        overflow: hidden
        p
            font-size: 0
            width: 100%
            max-height: 0
            background-color: #fff
            box-sizing: border-box
            padding: 0
            font-size: 0
            font-weight: 0
            opacity: 0.9
            transition: all 0.5s ease-in-out, all 0.5s ease-in-out
            overflow: hidden

    .CheckHouse-descr-answer-active
        padding: 5px
        font-size: 400
        font-size: 1.2rem
        max-height: 100%
        width: 100%
        transition: all 0.5s ease-in-out, all 0.5s ease-in-out

    .CheckHouse-descr-title
        margin-top: 40px
        margin-bottom: 15px
        font-size: 1.6rem
        font-weight: 500
    .CheckHouse-descr-li
        button
            display: block
            width: 100%
            border: none
            border-bottom: 1px solid #0000003f
            text-align: left
            font-size: 1.3rem
            background-color: #e7f3ff
            height: 50px
            cursor: pointer
    .CheckHouse-descr-rent-wrapp
        .CheckHouse-descr-rent-descr
            font-size: 1.3rem
            font-weight: 400
        .CheckHouse-descr-rent-list-title
            margin-top: 10px
            margin-bottom: 10px
            font-size: 1.2rem
            font-weight: 400
        .CheckHouse-descr-rent-advantages-li
            font-size: 1.2rem
            font-weight: 400
            padding: 5px
            background-color: #fff
        .CheckHouse-descr-rent-advantages-li.bg
            background-color: #e7f3ff
    .CheckHouse-btn
        margin-top: 20px
        width: 100%
        text-align: center
        font-size: 1.6rem
@media (max-width: 522px)
    .CheckHouse
        .CheckHouse-btn
            font-size: 1.3rem
            height: auto
            line-height: 1.3rem
            padding: 10px
