
.telegram
    max-width: 800px
    margin: 0 auto
    border: solid 1px black
    border-radius: 50px
    padding: 5px
    margin-bottom: 30px
    .telegram-white-border
        border-radius: 50px
        display: flex
        justify-content: space-between
        align-items: center
        .telegram-wrap
            border-radius: 50px
            border: solid 2px #fff
            background-color: #fff
            a
                text-decoration: none
            .t-item-wrap
                display: flex
                align-items: center
                padding: 10px
                background-color: #000
                border-radius: 50px
                width: 350px
                box-sizing: border-box

                .t-logo
                    width: 50px
                    background-color: #fff
                    border-radius: 50px
                    border: 1px solid #fff
                .t-text-wrap
                    text-align: start
                    padding: 10px
                    font-weight: 400
                    .t-title
                        margin: 0 auto
                        font-size: 0.9rem
                        color: #fff
                        font-weight: 400
                        border-bottom: 1px solid #fff
                        margin-bottom: 3px

                    .t-text
                        margin: 0
                        font-size: 0.7rem
                        color: #fff
                        font-weight: 400

@media screen and ( max-width: 767px  )
    .telegram
        border: none
        .telegram-white-border
            flex-direction: column
