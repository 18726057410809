.dataPagePopupFilterStyle
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  background-color: rgba(0, 0, 0, 0.7)
  z-index: 999
  &-inner
    background: white
    padding: 20px
    border-radius: 8px
.openFilterPopupBtn
  margin: 0 auto
  margin-top: 15px
  font-size: 15px
  height: 30px
  padding: 5 0
  line-height: 0
.closeFilterPopupBtn
  margin: 0
  margin-bottom: 10px
