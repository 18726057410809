.chatbot
    background-color: #fff
    border-radius: 10px
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5)
    margin: 20px
    padding: 20px
    text-transform: none
    .messages
        display: flex
        flex-direction: column
        height: 300px
        overflow-y: scroll
        margin-bottom: 10px

        .message
            padding: 10px
            margin: 10px
            border-radius: 5px
            max-width: 80%
            font-size: 1rem
            font-weight: 400

            &.user
                background-color: #e7f3ff
                color: #000
                align-self: flex-end

            &.bot
                background-color: #b0fbc1
                color: #000
                align-self: flex-start

    form
        display: flex

        input
            flex-grow: 1
            border-radius: 5px
            border: none
            padding: 10px
            margin-right: 10px
        .userText
            border: solid 1px #007bff
        button
            border: none
            background-color: #007bff
            color: #fff
            border-radius: 5px
            padding: 10px
            cursor: pointer
    .userText-form
        display: flex
        flex-wrap: wrap
        gap: 15px
