@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600&display=swap')

body
    margin: 0
    padding: 0
    box-sizing: border-box
    background-color: #f2f2f2
    color: #222
    font-weight: 500
    font-family: 'Roboto', sans-serif

.App
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100vh
    @media (min-width: 1200px)
        width: 1140px
        margin: 0 auto
ul, li, p, h1, h2, h3
    margin: 0
    padding: 0
li
    margin: 0
    padding: 0
    list-style: none
.link-text-decor-none
    text-decoration: none

.politica
    padding: 40px 15px
    h2
        font-size: 1.5rem
        margin-bottom: 10px

    p
        font-size: 1.2rem
        font-weight: 400
.homeBeginBtn, .btn
    display: inline-block
    box-sizing: border-box
    margin-right: 10px
    padding: 0 20px
    outline: none
    border: none
    border-radius: 6px
    height: 60px
    line-height: 3.7rem
    font-size: 1.8rem
    font-weight: 500
    color: #ffffff
    background-color: #50a4f9
    cursor: pointer
    user-select: none
    appearance: none
    touch-action: manipulation
    text-decoration: none
    text-transform: uppercase
    &:focus-visible
        box-shadow: 0 0 0 2px #666

    &:hover
        background-color: #75b8f7

.homeBeginBtnDataPage
    margin-bottom: 30px

// BuyForm
.form
    margin-bottom: 40px
    margin-top: 40px
    form
        display: flex
        flex-direction: column
        align-items: center
        gap: 16px
        font-weight: 400
        p
            margin-bottom: 10px
        label
            font-size: 1.2rem
            color: #333
        div
            display: flex
            flex-direction: row
            align-items: center
            gap: 20px
            text-transform: uppercase
            p
                margin: 0
            input
                margin: 0
                margin-left: 10px
                width: 20px
            label
                display: flex
                flex-direction: row

        input, textarea, select
            font-size: 1.1rem
            padding: 8px
            border: 1px solid #ccc
            border-radius: 4px
            width: 400px
            box-sizing: border-box
        textarea
            overflow: auto
            resize: none
            height: 220px

        button
            margin: 0
            font-size: 1.2rem
            line-height: 1.2rem
            height: 40px

@media screen and ( max-width: 420px )
    .form
        form
            input, textarea, select
                width: 43vh
