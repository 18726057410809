/* Общие стили для формы */
.dataPageFilterStyle
  form
    display: flex
    flex-direction: column
    margin: auto
    width: 80%
    padding: 20px
    background-color: #f0f0f0
    border-radius: 10px

    label
      margin: 0 auto
      display: flex
      flex-direction: column
      margin-bottom: 10px
      width: calc(50% - 10px)
      select
        padding: 5px

      select,
      input[type='radio']
        margin-top: 5px

    /* Стили для кнопки */
    button
      padding: 10px
      background-color: #007BFF
      color: white
      border: none
      border-radius: 5px
      cursor: pointer
      margin-top: 10px
      text-transform: uppercase

    button:hover
      background-color: #0056b3

    /* Стили для div-контейнера, содержащего радио-кнопки */
  div
    display: flex
.dataPageRadio
  div
    display: block !important
    label
      margin-left: 10px
      flex-direction: row
      align-items: center
      input
        margin-top: 0 !important

@media (max-width: 600px)
  .dataPageFilterStyle
    form
      flex-direction: row
      flex-wrap: wrap
      justify-content: space-between
      label
        width: calc(100% - 1px)
      button
        margin: 0 auto
