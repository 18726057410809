.advertise-title
	margin-top: 20px
	text-align: center
.advertise-wrap
	display: flex
	justify-content: center
	align-items: center
	margin-top: 20px
	margin-bottom: 20px

	.advertise-form
		width: 80% /* Можно изменить по вашему вкусу */
		padding: 20px
		background-color: #fff
		border-radius: 10px
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)

		& label
			display: flex
			flex-direction: column
			font-size: 0.9em
			font-weight: bold
			color: #444
			gap: 3px
			margin-top: 20px

		.kitchenType,
		.furniture
			label
				flex-direction: row
				gap: 5px
				margin-top: 5px
		.additionalInfo

			textarea
				font-size: 1rem
				resize: none
				height: 200px

	.advertise-form input,
	.advertise-form textarea,
	.advertise-form select
		padding: 10px
		border: 1px solid #ccc
		border-radius: 5px
		font-size: 0.9em
		color: #444

	.advertise-form button
		padding: 10px 20px
		background-color: #007BFF
		color: #fff
		border: none
		border-radius: 5px
		cursor: pointer
		font-size: 1em
	.ToAdvertise-btn
		text-align: center
		margin-top: 20px
		button
			text-transform: uppercase
			&:hover
				background-color: #0056b3
