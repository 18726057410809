
.image-slider__main-image
    width: 100vw
    height: 100vh
    display: flex
    flex-direction: column
    align-items: center
    img
        position: absolute
        width: auto
        height: 90vh
        opacity: 0
        transform: translateY(-20px)
        transition: opacity 0.3s, transform 0.3s

    img.active
        opacity: 1
        transform: translateY(0)

.image-slider__thumbnails
    width: 100vw
    max-height: 100px
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap
    gap: 10px
    position: absolute
    bottom: 0
    overflow: auto

    img
        width: 60px
        height: 60px
        object-fit: cover
        cursor: pointer

    img.active
        border: solid 3px #fff

@media (max-width: 821px)
    .image-slider__main-image
        img
            width: 100%
            height: auto
    .image-slider__thumbnails
        max-height: 250px
