// seler 
.lastRequestsTitle
    font-size: 1.5rem
    font-weight: 500
    padding: 20px 10px
    text-align: center

.last-requests
    // margin-top: 72px
    text-align: center
    padding: 30px

    input
        width: 400px
        padding: 12px 20px
        margin: 8px 0
        box-sizing: border-box
        border: 1px solid #ccc
        border-radius: 4px
        font-size: 16px
    h2
        color: #222
    p
        margin-top: 20px
    &-wrap
        margin-top: 20px
        display: flex
        justify-content: space-between
        flex-wrap: wrap

        .lastRequestCardsWrap
            position: relative

            .slick-dots
                display: flex !important
                flex-wrap: wrap
                justify-content: center
                bottom: -20px
                li
                    border: none !important
                li.slick-active
                    button:before
                        color: #337AB7
                button:before
                    font-size: 15px

            .lastRequestCard
                border: 1px solid #918f8f
                border-radius: 10px 10px 0 0
                padding: 10px
                width: 400px
                height: 500px
                overflow: auto
                margin-bottom: 10px

                li
                    display: flex
                    justify-content: space-between
                    border-bottom: 1px solid #00000041
                    img
                        width: 100px
                        height: 100px
                        margin-left: 5px
                    .big
                        transition: all 500ms ease
                        width: 100%
                        height: 100%

                    p:first-child
                        font-weight: 600
                        font-size: 20px

                    p
                        text-align: end
                        margin: 0
                        margin-bottom: 5px
                        font-size: 15px
                        font-weight: 400
                        color: #222
                    .date
                        font-size: 15px
                .description
                    display: block
                    text-align: start
                    p
                        text-align: start

                .imgLi
                    overflow: auto
                .toast
                    h4
                        color: green
                        margin: 0
            .lastRequestCardDataPage
                height: auto
            .estimate
                font-size: 20px
                padding: 15px
                border-radius: 10px
                background-color: #4CAF50
                position: absolute
                right: 5px
                bottom: 5px
                cursor: pointer
                border: none
                transition: all 0.3s ease
                &:hover
                    opacity: 0.5
            .cartTrash
                position: absolute
                top: -13px
                width: 25px
                height: 20px
                display: flex
                justify-content: center
                align-items: center

            .editButton
                left: 37px

            .cardVerifiedWrapper
                border: solid 1px green
                padding: 15px
                border-radius: 5px

                .cardVerified
                    color: green
                    border: solid 1px green
                    padding: 5px
                    border-radius: 5px
                    text-transform: uppercase
                    cursor: pointer
                .cardVerifiedText
                    font-size: 11px
            .cardVerifiedWrapperSellerDataPageItem
                margin-bottom: 10px
        .hide
            display: none
.last-requests-cabinet
    margin: 0
    h2
        text-transform: uppercase
.allReviews
    margin-top: 0
@media (max-width: 820px)

    .last-requests
        margin-top: 50px
        input
            width: 85vw
            height: 55px
            padding: 15px 15px
            font-size: 1.3rem
            margin-bottom: 30px
        h2
            font-size: 1.7rem
        p
            font-size: 1.5rem

        &-wrap
            width: 80vw
            margin: 0 auto
            margin-top: 20px
            justify-content: center
            .lastRequestCardsWrap
                justify-content: center
                .lastRequestCard
                    width: 80vw
                    height: 700px
                    li
                        img
                            width: 150px
                            height: 150px
                        .date
                            font-size: 1.4rem
                        p:first-child
                            font-size: 1.6rem

                        p
                            font-size: 1.4rem
                    .CardLi
                        display: block
                        p
                            text-align: start
                    button
                        margin-top: 10px
                        font-size: 1.3rem
                        padding: 5px
                        border-radius: 10px
                    .cartTrash
                        padding: 10px
                .lastRequestCardDataPage
                    height: auto

@media (max-width: 400px)
    .lastRequestsTitle
        font-size: 1.3rem
    .last-requests
        margin: 0

        h2
            font-size: 1.4rem
        p
            font-size: 1.2rem

        &-wrap
            margin-top: 20px
            justify-content: center
            .lastRequestCardsWrap
                justify-content: center
                .lastRequestCard
                    width: 340px
                    height: 500px
                    li
                        img
                            width: 150px
                            height: 150px
                        .date
                            font-size: 15px
                        p:first-child
                            font-size: 15px

                        p
                            font-size: 15px

                    button
                        margin: 0
                        padding: 0
                        font-size: 1rem
                        border-radius: 10px
                        right: 25px
                        bottom: 5px
                .lastRequestCardDataPage
                    height: auto
