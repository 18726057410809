.ChatBotPage
  display: flex
  flex-direction: column
  align-items: center
  box-sizing: border-box
  max-width: 800px
  width: 100vw
  margin: 0 auto
  padding: 10px
  background-color: #f7f7f7 // Светлый фон для всей страницы

  .ChatBotPageTitle
    text-align: center
    margin-bottom: 20px
    font-weight: 500
    color: #333 // Темно-серый цвет текста для заголовка

  .ChatBotPageForm
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    gap: 10px

    .chat-history
      box-sizing: border-box
      max-height: 500px
      overflow-y: auto
      background-color: #fff
      border: 1px solid #ccc
      padding: 10px
      margin-top: 20px
      display: flex
      flex-direction: column
      gap: 10px
      width: 100%

      .message
        display: flex
        align-items: center
        gap: 5px
        &.user
          background-color: #d1eaff // Светло-голубой фон для сообщений пользователя
          align-self: flex-end

        &.bot
          background-color: #e1e1e1 // Светло-серый фон для сообщений бота
          align-self: flex-start

      .message-sender
        font-weight: bold

        &.user-sender
          color: #4a90e2 // Синий цвет для пользователя

        &.bot-sender
          color: #da3b01 // Красный цвет для бота

      .message-text
        font-size: 16px
        overflow-wrap: break-word

  .ChatBotPageInput
    width: 90%
    padding: 10px
    font-size: 16px
    border: 1px solid #ccc
    border-radius: 4px
    margin-bottom: 10px

  .ChatBotPageBtn
    font-size: 16px
    padding: 10px 20px
    background-color: #5cacf2 // Голубой цвет кнопки
    border-radius: 5px
    border: none
    color: white
    cursor: pointer
    transition: background-color 0.3s
    &:hover
      background-color: darken(#5cacf2, 10%)

  .ChatBotPageQuickQuestions
    width: 90%
    margin-top: 10px
    display: flex
    flex-direction: column
    gap: 5px

    .ChatBotPageQuickQuestionsBtn
      font-size: 16px
      padding: 10px 20px
      background-color: #ededed // Серый фон для кнопок быстрых вопросов
      border-radius: 5px
      border: 1px solid #ccc
      cursor: pointer
      transition: background-color 0.3s
      &:hover
        background-color: #e2e2e2
  .ChatBotPageIli
    margin-top: 5px
