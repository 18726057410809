.buyItemWrapp
    padding: 40px 15px
.buyItem

    text-align: center
    h2, h3, p
        text-transform: uppercase
    h2
        font-size: 1.5rem
        margin-bottom: 10px
    h3
        font-size: 1.2rem
        margin-bottom: 10px
    p
        font-size: 1.1rem
        margin-bottom: 30px

.apartmentForm
    margin: 0 auto
    margin-bottom: 40px
    .title
        text-align: center
        margin-bottom: 20px
        margin-top: 30px
        text-transform: uppercase
        font-size: 1.5rem
    form
        gap: 1px

.cartHeader
    display: grid
    grid-template-columns: 3fr repeat(5, 1fr)
    grid-template-rows: 1fr
    grid-column-gap: 0px
    grid-row-gap: 0px
    margin-bottom: 1px
    background: #e7f3ff

    div
        background: #e7f3ff
        font-size: 1.3rem
        color: #385898
        font-weight: 500
        padding: 10px
        display: flex
        align-items: center
        margin: 0 auto
    .cartHeaderDescr
        min-width: 255px
.cartWrapp
    margin-bottom: 5px
    .cartDescrMedia
        display: none
    .cart
        display: grid
        grid-template-columns: 3fr repeat(5, 1fr)
        grid-template-rows: 1fr
        grid-column-gap: 0px
        grid-row-gap: 0px
        background: #fff
        div
            background: #fff
            font-size: 1.2rem
            font-weight: 400
            padding: 10px
            display: flex
            align-items: center
            margin: 0 auto
        .cartDescr
            min-width: 255px

@media screen and ( max-width: 771px )

    .cartHeader
        grid-template-columns: repeat(5, 1fr)
        .cartHeaderDescr
            display: none

    .cartWrapp

        .cartDescrMedia
            display: block
            background: #fff
            padding: 5px
            align-items: center
            font-weight: 400
            .descriptionrMedia
                padding-right: 5px

        .cart
            grid-template-columns: repeat(5, 1fr)
            .cartDescr
                display: none

            .rentalPeriod
                font-size: 0.8rem

@media screen and ( max-width: 577px )

    .cartHeader
        div
            font-size: 1.1rem

    .cartWrapp
        .cart
            div
                font-size: 1rem

@media screen and ( max-width: 550px )
