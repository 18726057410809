.registrationFormWrap

  .registrationFormTitle
    text-align: center
    font-size: 1rem
    padding: 20px 10px
    font-weight: 500
  .registrationForm
    padding-bottom: 20px
    form, fieldset
      display: flex
      flex-direction: column
      width: 300px
      margin: 0 auto
    fieldset
      border: none
    label
      margin-bottom: 10px

      input
        width: 90%
        padding: 10px
        border-radius: 4px
        border: 1px solid #ccc
        margin-top: 5px

      span
        color: red
        font-size: 14px
        margin-top: 5px

    button
      margin: 0 auto
      width: 200px
    .regBtn
      width: auto
      height: auto
    .privacy
      text-align: center
      margin-top: 10px
      font-size: 0.8rem
@media screen and ( max-width: 420px )
  .registrationFormWrap
    .registrationFormTitle
      font-size: 1.3rem
    .registrationForm
      form
        display: flex
        flex-direction: column
        width: 300px
        margin: 0 auto
