
.token
    h1
        text-align: center
        font-size: 1.9rem
    p
        text-align: center
        margin-top: 10px
        margin-bottom: 25px
        font-size: 1.3rem
    a
        margin-bottom: 10px
    .project-in-development
        text-align: center
        margin-bottom: 20px
        color: red
    .token-acc-wrap
        button
            font-size: 1.5rem
        .CheckHouse-descr-answer-active
            p
                text-align: left
                padding: 5px
                font-size: 400
                font-size: 1.2rem
                max-height: 100%
                width: 100%
                margin-bottom: 1px
                ransition: all 0.5s ease-in-out, all 0.5s ease-in-out

    .CheckHouse-descr
        margin-bottom: 20px
        .CheckHouse-descr-li
            th
                padding: 5px
            td
                padding: 5px
    .token-buy-link
        display: block
        text-align: center
    .token-form-wrapp
        display: flex
        flex-direction: column
        max-width: 800px
        margin: 0 auto
        margin-top: 20px
        .token-form-input
            margin-bottom: 15px
        span
            color: red
        .btn
            margin: 0
            margin-top: 15px
        .input-wrapp
            display: flex
            align-items: center
            input
                width: 100%
                box-sizing: border-box
                padding: 10px
                font-size: 1.1rem
                border: 1px solid #ccc
                border-radius: 4px

            .okImg
                width: 40px
            .matched
                border-color: green

@media screen and ( max-width:  805px)
    .token
        .token-acc-wrap
            button
                font-size: 1.2rem
@media screen and ( max-width:  400px)
    .token
        .token-acc-wrap
            button
                font-size: 1rem
