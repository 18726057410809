.articleList
    margin-top: 60px
    margin-bottom: 60px

    .articleListWrap
        margin-top: 40px
        display: grid
        grid-template-columns: repeat(4, 1fr)
        grid-column-gap: 15px
        grid-row-gap: 15px

    .cartWrap
        width: 270px
        height: 355px
        overflow: hidden
        background-color: #fff
        position: relative
        .cartTrash
            position: absolute
            top: 0
            left: 0
        .imgtWrap
            height: 60%
            margin: 0 auto
            text-align: center
            img
                max-width: 100%
                height: 100%

        .textWrap
            padding: 5px
            text-align: center

            h2
                font-size: 1.1rem
                margin-bottom: 15px
                color: #000
                font-weight: 500
        .articleTime
            font-size: 0.6rem
            position: absolute
            bottom: 3px
            left: 3px
            opacity: 0.5

@media screen and ( max-width: 1025px )
    .articleList
        .articleListWrap
            grid-template-columns: repeat(2, 1fr)
            .cartWrap
                margin: 0 auto
@media screen and ( max-width: 565px )
    .articleList
        .articleListWrap
            grid-template-columns: repeat(1, 1fr)
            .cartWrap
                width: 90vw
@media screen and ( max-width: 376px )
