.buyItem
    padding: 40px 15px
    text-align: center
    img
        max-width: 60%
        height: auto
    h2
        margin-bottom: 40px
        margin-top: 60px
    p
        font-size: 1.2rem
        font-weight: 400
        margin-bottom: 10px
    .description
        text-align: left

@media screen and ( max-width: 551px )
    .buyItem

@media screen and ( max-width: 422px )

@media screen and ( max-width: 376px )
    .buyItem
