
footer

    .footerWrapp
        margin: 0 auto
        max-width: 1140px
        display: flex
        align-items: center
        justify-content: space-between
        padding: 0 10px
        height: 80px
        box-sizing: border-box
        border: none
        color: #fff
        background-color: #000

        div

            p
                max-width: 310px

        .Link
            font-size: 1rem
            color: #fff
            text-decoration: none
        .logo
            width: 50px
            img
                width: 100%
@media (max-width: 820px)
    .footerWrapp
        max-width: 820px
        div

            p
                margin-right: 0.8rem
                max-width: 310px
        .Link
            font-size: 0.8rem !important
            text-align: right

@media (max-width: 400px)
    .footerWrapp
        max-width: 400px
        padding: 5px 0
        height: 50px

        div
            max-width: 180px
            p
                margin: 0
                margin-right: 10px
                margin-left: 10px
                font-size: 0.6rem

        .Link
            font-size: 0.6rem !important
            max-width: 150px
